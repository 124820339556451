import React from 'react';
import {CardContainer} from 'sections/OurProjects/CardContainer';
import {PageWrapper, OverflowWrapper} from 'shared/Styles/Wrappers';
import {SortingTopBar} from 'sections/OurProjects/SortingTopBar';
import {BrushedTextContainer} from 'components/BrushedTextContainer';
import SEO from 'components/seo';

const OurProjects = ({data}) => {
  return (
    <>
      <SEO title={data.seo.title}/>
      <OverflowWrapper>
        <PageWrapper>
          <SortingTopBar data={data.projects.filters}/>
          <BrushedTextContainer x={[
            -10,
            15,
          ]} posY="-150px">
            {data.parallaxText}
          </BrushedTextContainer>
          <CardContainer data={data.projects.cards} cardBtnText={data.projects.cardBtnText}/>
        </PageWrapper>
      </OverflowWrapper>
    </>
  );
};

export default OurProjects;
