import React from 'react';
import OurProjects from '../../sections/OurProjects';
import {useData} from '../../data/en/our-projects';

const Index = () => {
  const data = useData();

  return (
    <OurProjects data={data}/>
  );
};

export default Index;
